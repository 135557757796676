@import "index";

#splash-view, .splash {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; }

#splash-view {
  position: absolute;
  visibility: visible;
  top: 0;
  left: 0;
  z-index: 1000;
  opacity: 1.0 !important;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
  transition-delay: 2s;
  height: 100vh;
  width: 100vw;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;

  padding: {
    bottom: 120px; }

  .splash.wrapper.container {
    margin: 0 auto;
    max-width: 550px;
    height: 100%;
    width: 100%;

    .wrapper.logo {
      svg {
        min-width: 120px !important;
        min-height: 20px !important;
        max-width: 120px !important;
        margin: 20px; } } } }


#splash-view.out {
  opacity: 0.0 !important;
  visibility: hidden; }

#splash-view.off {
  display: none !important; }
