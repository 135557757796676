@import "index";

.title {
  color: white; }

.subject {
  color: $col-accent !important; }

.calendar.wrapper {
  .react-calendar {
    width: 100%;
    background: {
      color: transparent; }
    border: none;

    & * {
      color: white !important;

      .react-calendar__month-view__days__day--neighboringMonth {
        opacity: 0.6; } } }

  .react-calendar__navigation {
    height: 50px; }

  .react-calendar__month-view__weekdays__weekday {
    abbr {
      text-decoration: none !important; } }

  .react-calendar__tile,
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    @extend .trans;

    &:hover {
      background: {
        color: $col-lgrey; }

      abbr {
        color: white !important; } } }


  .react-calendar__tile.react-calendar__month-view__days__day {
    min-height: 60px; }

  .react-calendar__tile.react-calendar__tile--active.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd.react-calendar__tile--rangeBothEnds.react-calendar__month-view__days__day {
    background: {
      color: $col-llgrey1; }

    abbr {
      color: $col-main !important; } } }

.article-close.wrapper svg {
  color: white !important;
  fill: white !important; }
