@import "./index";

$mar: 40px;
$mar-MOB: 60px;

.article.wrapper {
  &.inactive {
    top: -100%; }

  overflow: scroll;
  position: fixed;
  z-index: $zidx-top-max + 100;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  pointer-events: visible;

  .article-close.wrapper {
    position: absolute;
    right: 20px;
    top: 10px;

    .icon.wrapper, svg {
      @extend %rel-right;
      width: 30px !important;
      height: 30px !important; }

    a, span {
      &:hover {
        text-decoration: none;
        color: white !important; } }
    font: {
      size: 30px; } }

  article {
    max-width: 720px;
    margin: 0 auto;

    .title, p, h4, h1, h2, h3 {
      &::-moz-selection {
        color: white !important;
        background: rgba(25, 90, 252, .5) !important; }
      &::selection {
        color: white !important;
        background: rgba(25, 90, 252, .5) !important; } }

    .subject {
      margin: {
        top: 50px !important; } }

    .title {
      margin: {
        top: 15px !important;
        bottom: 45px; } }

    @media screen and (max-width: 980px) {
      padding: {
        left: $mar-MOB + 30;
        right: $mar-MOB + 30;
        bottom: $mar-MOB; }

      @media screen and (max-width: 580px) {
        padding: {
          left: $mar-MOB / 2;
          right: $mar-MOB / 2;
          bottom: $mar-MOB; } } } } }

.empty.article {
  display: none !important; }
