@import "./index";

$header-logo-height: 20px;
$header-transition: 0.9s;
$header-transition-del: 0.2s;

header#header {
  position: fixed;
  z-index: $zidx-header;
  width: 100vw;
  background-color: $col-main;

  .wrapper {
    padding: $header-logo-height - 5 0;
    background-color: white;
    margin: 0 auto;
    position: relative;

    display: flex;
    flex-direction: row;
    align-items: center;

    @media screen and (max-width: 500px) {
      margin: {
        left: 0; }
      padding: {
        left: 10px; } }

    .sides {
      position: absolute !important; }

    #social-icons.wrapper {
      @media screen and (max-width: 500px) {
        display: none; }

      left: 0;
      margin: 0 0 0 20px;

      .icon.wrapper {
        @extend %svg-temp; }

      svg {
        &:hover {
          fill: $col-accent;
          cursor: pointer;

          transition-timing-function: $bezier !important; }
        &:last-of-type {
          margin-right: 0; } } }

    #logo.wrapper {

      svg {
        height: $header-logo-height;
        min-width: 100px;
        max-width: 100px; }

      height: $header-logo-height;
      min-width: 100px;
      max-width: 100px;

      img {
        width: 100%; }

      #home-logo {
        max-width: 100px;
        height: $header-icon-max-height + 10;
        background-color: red; } }

    #current-event.wrapper {
      &:hover {
        cursor: default; }

      @extend %rel-right;
      height: $header-height;
      margin: 0 20px 0 0;
      letter-spacing: 1.4px;

      .days, .event-txt {
        font: {
          weight: 900; } }

      .event-txt {
        color: orangered;
        font: {
          style: italic; } } } }

  .wrapper.underline {
    &:before {
      background: {
        color: $col-accent; }
      -webkit-transition: all $header-transition $bezier $header-transition-del;
      transition: all $header-transition $bezier $header-transition-del; } } }
