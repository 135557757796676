@import "./index";

.welcome {
  text-transform: uppercase;
  text-align: center;
  margin: 0 auto;
  letter-spacing: 5px;
  font: {
    size: 50px;
    weight: 300 !important; }

  @media screen and (max-width: 720px) {
    font-size: 30px;
    font-size-adjust: 2.0; }

  > .spacer.wrapper {
    display: block;
    height: 40px; } }

#homepageNav {

  padding: {
    top: 20px;
    bottom: 30px; }

  .navList.wrapper {
    display: flex;
    justify-content: center;
    margin: 0 auto;

    ul {
      display: inline-block;
      list-style: none;
      padding: 0;
      margin: 20px;

      li {
        display: inline-block;
        margin: 0 20px;

        .item.wrapper {
          z-index: 20;
          padding: 10px;
          text-align: center; }

        &:last-of-type {
          margin-right: 0; }
        &:first-of-type {
          margin-left: 0; } } } } }

//.logo
////&.big.wrapper
////  margin:
////    bottom: 20px
////  width: 100px
////  height: 50px
////  background-color: #61dafb
