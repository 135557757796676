@import "general";

$selectionColor: rgba(255, 255, 255, 0.99);
//$selectionBackground: transparentize($col-accent, 0.01)
$selectionBackground: rgba(0, 0, 0, 0.99);

body {
  margin: 0;
  padding: 0;
  font-family: "myFirstFont", "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  //background:
  //  color: $col-main

  * {
    box-sizing: border-box;
    color: #2a2a2a;

    &::-moz-selection {
      color: $selectionColor;
      background: $selectionBackground; }
    &::selection {
      color: $selectionColor;
      background: $selectionBackground; } } }

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }
/* Non-prefixed version, currently supported by Chrome and Opera */


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

h2 {
  margin: 0; }

ul {
  list-style: none; }

a {
  text-decoration: none;
  &:hover {
    text-decoration: underline; } }

header {
  top: 0;
  left: 0; }

//div#mainHome
//  position: relative
//  //z-index: $zidx-mid-min

div#mainContent {
  position: relative;
  z-index: $zidx-mid-max; }

footer {
  bottom: 0; }
//height: $footer-height

p {
  //font:
  //  size: 1.1em
  line-height: 1.7em; }
