#mainHome {
  background-color: deeppink; }

.homepageNav.wrapper {
  background-color: white;
  padding: {
    top: 30px; }

  #homepageNav {
    .logo {
      margin: 0 auto; } } }
