@import "./index";

.slideshow {
  height: 400px;
  display: inline;

  &.wrapper {
    width: 100%;
    background: {
      color: #3c74d6; }

    .slide, .slide-img {
      margin: 0 auto;
      height: 700px;

      @media screen and (max-height: 1000px) {
        height: 480px; }

      @media screen and (max-width: 1300px) {
        height: 550px !important;

        @media screen and (max-width: 1000px) {
          height: 400px !important; } }

      background: {
        color: #2f4cd6; }
      //max-width: 720px
      width: 100%;
      background: {
        clip: content-box;
        position: center;
        size: cover; } }

    &.wrapper {
      overflow: hidden; } } }
