@import "./index";

$curr-mar: 20px;

main#main {
  &:before {
    display: block;
    height: $header-height;
    content: "";
    padding: 0 !important; }

  position: relative;

  margin: {
    top: 0;
    bottom: 100px; }

  .current.wrapper {
    padding: $curr-mar $curr-mar;

    max-width: 720px;
    margin: 0 auto;

    .title {
      margin-bottom: 30px; }

    .subject {
      color: $col-accent;
      margin: {
        bottom: 20px; } }

    .paragraphs {
      p {
        margin: {
          bottom: 20px; }

        &:last-of-type {
          margin: {
            bottom: 0 !important; } } } }

    .last-sermon {
      margin-top: 0;

      .last {
        color: $col-lgrey;
        margin: {
          right: 5px; } }
      .last-title {
        font: {
          weight: bolder; } } } } }

$arr: 18px;
span.icon.arrow {
  position: relative;
  margin: {
    left: 10px; }

  svg {
    position: relative;
    top: 4px;
    min-width: $arr !important;
    min-height: $arr !important;
    max-width: $arr !important;
    max-height: $arr !important; } }
