@import "./index";

.mobile.menu.items.wrapper {
  margin-top: 30px !important;
  padding: 30px !important;
  padding: {
    bottom: 75px !important; }
  width: 100vw;

  @extend .softShadow-1;

  ul {
    padding: 0;
    margin: auto;
    list-style: none;
    span {
      text-align: center !important;
      .link.wrapper.trans {
        margin: 0 auto; } }

    a {
      margin: 0 auto; } }

  position: absolute;
  top: 0;
  height: auto;

  .nav-item {
    .wrapper {
      padding: 10px 0 !important; } } }

.burger-toggle.wrapper {
  .icon.wrapper svg {
    @extend .trans;
    transform: rotateZ(0); }

  &.open {
    .icon.wrapper svg {
      transform: rotateZ(90deg); } } }

.wrapper.bottom {
  background: rebeccapurple;
  margin: 20px auto;
  padding: 0 !important;
  position: absolute;
  left: -12px;

  #social-icons.wrapper.mobile {
    margin: 25px 0 !important;
    padding: 0;
    @media screen and (max-width: 500px) {
      display: flex !important; }

    svg {
      &:hover {
        fill: $col-accent;
        cursor: pointer;

        transition-timing-function: $bezier !important; }
      &:last-of-type {
        margin-right: 0; } } } }
