$header-height: 40px;
$footer-height: 600px;
$window-top: $header-height;

$col-main: #555d6e;
$col-main-1: #ff3500;
$col-accent: #195AFC;
$col-accent-alt: #19FCE3;
$col-lgrey: #9A9A9A;
$col-llgrey: #EEEEEE;
$col-llgrey1: #eaeaea;

$rad-round: 3px;

////// Z-IDX
$zidx-mid-min: 0;
$zidx-mid-max: 100;
$zidx-bg-min: $zidx-mid-min * -1;
$zidx-top-min: $zidx-mid-max + 1;
$zidx-top-max: $zidx-top-min + 100;
$zidx-footer: $zidx-bg-min - 10;
$zidx-header: $zidx-top-max;

////// TRANSITION FUNCTIONS
$bezier: cubic-bezier(0, .71, .13, 1);

$header-icon-max-height: 20px;

.trans {
  transition: {
    duration: 150ms;
    timing-function: ease-in; }

  &.delay {
    transition: {
      delay: 100ms; } }

  &.long {
    transition: {
      duration: 250ms; } } }

.hidden {
  display: none; }

svg.small {
  min-width: $header-icon-max-height;
  min-height: $header-icon-max-height;
  max-width: $header-icon-max-height;
  max-height: $header-icon-max-height;
  margin-right: 25px; }

.noScroll {
  overflow: hidden;
  position: fixed; }

.noShadow {
  box-shadow: 0 0 0 0 black; }

.softShadow {
  box-shadow: 0 -50px 100px 0 black; }

.softShadow-1 {
  box-shadow: 0 30px 100px -50px transparentize(black, 0.5); }

.softShadow-1-off {
  box-shadow: 0 30px 100px -50px transparentize(black, 1); }

.divider {
  position: relative;
  background: {
    color: $col-accent; }
  height: 2px;
  width: 75%;
  max-width: 175px;
  margin: {
    top: 20px;
    bottom: 20px; }

  &.left {
    left: 0; }

  &.full {
    width: 100%; } }

.underline {
  &:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1); }
  &:before {
    content: "" !important;
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background: {
      color: $col-accent; }
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s; } }

article {
  .subject {
    color: #35e7fb;
    margin: {
      top: 40px;
      bottom: 10px; } }

  .title {
    font: {
      size: 3em; }
    margin-top: 0;
    text-transform: uppercase; }

  .title, .subject {
    letter-spacing: 0.13em;
    text-align: center;
    font: {
      weight: 400; } } }

.height-spacer {
  width: 100%;
  position: relative;
  height: 10px;

  &.big {
    height: 100px; }

  &.medium {
    height: 50px; }

  &.small {
    height: 20px; }

  &.medium-1 {
    height: 65px; } }

.rounded {
  border-radius: $rad-round !important; }

%svg-temp {
  min-width: $header-icon-max-height;
  min-height: $header-icon-max-height;
  max-width: $header-icon-max-height;
  max-height: $header-icon-max-height;
  margin-right: 25px; }

svg {
  @extend %svg-temp; }

%rel-right {
  right: 0;
  position: relative; }
