@import "./index";

footer#footer {
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -100%;
    left: 0;
    width: 100%;
    height: 100%;
    //background:
    //  color: red
    @extend .softShadow; }

  position: relative;
  //z-index: $zidx-footer
  width: 100vw;
  background-color: $col-llgrey;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 75px;
  padding-bottom: 8px;

  //@media screen and (max-width: 720px)
  //  padding:
  //    left: 20px

  .coloumns.wrapper {
    justify-self: center;
    //max-width: 720px + 20px
    //padding:
    //  left: 80px
    ////right: 120px
    display: flex;
    flex: {
      direction: row;
      wrap: nowrap; }

    @media screen and (max-width: 620px) {
      justify-self: start;
      flex: {
        direction: column;
        wrap: wrap; } }

    align-items: flex-start;
    //justify-content: space-around

    .footer-column.wrapper {
      //min-width: 300px
      width: 100%;
      justify-self: center;
      margin: {
        left: 20px;
        right: 20px; }

      @media screen and (max-width: 720px) {
        justify-self: start;
        margin: {
          top: 20px; } }

      .header {
        letter-spacing: .1em;
        text-transform: uppercase; }

      .divider {
        max-width: 75px; }

      .footer-items {
        min-width: 150px;
        .footer-items {
          padding: 0;

          .footer-item {
            line-height: 30px; } } } } }

  .last.wrapper {
    margin-top: 75px;
    text-align: center;

    address {
      font-style: normal;
      font-size: 1em;
      font-weight: 600;

      span {
        line-height: 1.75em; } }

    .small {
      margin-top: 75px; }

    .small, .small span {
      color: #cfcfcf; }

    .glyphicon {
      margin: {
        left: 4px;
        right: 2px; }
      &.active {
        color: $col-accent; } } } }

