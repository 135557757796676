@import "../../Styles/Header";

#mobile-menu {
  & .wrapper.sides {
    @extend %rel-right; }

  position: relative;
  right: 0;
  height: $header-height;
  padding: 0 !important;

  .burger-toggle {
    width: 100%;
    padding: 0 !important;
    margin: 0 !important; }

  #mobile-menu-wrapper.wrapper {
    position: absolute;
    right: 0;
    top: 0;
    background: transparent !important;
    opacity: 0;

    &.active {
      opacity: 1;
      z-index: 10; } } }

//margin: 0 20px 0 0
