@import "./index";

.maps.wrapper {
  margin: 75px auto;
  background: {
    color: $col-llgrey1; }
  width: 100%;
  height: 300px;
  border-radius: 10px;

  box-shadow: 0 20px 40px -40px $col-lgrey; }
