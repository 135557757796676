@import "./index";

$pad-box: 20px;
$inc: 5px;

.item.wrapper {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none; }

    span {
      .link.wrapper {
        position: relative;
        padding: $pad-box;
        //margin: $inc
        box-shadow: 0 0 0 0 transparent;

        @extend .rounded;
        overflow: hidden;

        &:before {
          @extend .trans;
          transition-delay: 75ms;
          content: '';
          display: block;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 0;
          background-color: $col-accent; }

        &:hover {
          padding: $pad-box;
          //margin: 0
          box-shadow: 0 5px 20px -5px $col-llgrey1;

          &:before {
            height: 4px; } } } } } }
