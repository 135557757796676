@import "index";

#error.error.wrapper {
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0 !important;
  background: {
    color: transparentize($col-llgrey1, 0); }
  padding: 60px;

  main {
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    a {
      &:hover {
        text-decoration: none; }

      display: flex;
      flex-direction: column; } } }

#bible-verse {
  margin: {
    top: 0; }
  line-height: 40px; }

.home-link {
  max-width: 230px;
  padding: 20px;
  border: 5px solid $col-llgrey;
  display: flex;
  background: transparent;
  justify-content: center;
  margin: {
    top: 40px; }
  @extend .softShadow-1-off;

  > h5 {
    color: $col-accent !important; }


  &:hover {
    border: 5px solid transparent;
    background: $col-llgrey;
    @extend .softShadow-1; } }


.error.code.bg.wrapper {
  font: {
    size: 40px;
    family: 'B612 Mono', monospace; }
  //position: relative
  //z-index: -2
  display: flex;
  align-self: center;
  justify-self: center;
  margin: {
    bottom: 30px; } }

.err-code {
  //color: #ffc45f
  color: $col-accent; }
